import { render, staticRenderFns } from "./ClaimRewardsBar.vue?vue&type=template&id=1feb2dc4&scoped=true&"
import script from "./ClaimRewardsBar.vue?vue&type=script&lang=ts&"
export * from "./ClaimRewardsBar.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimRewardsBar.vue?vue&type=style&index=0&id=1feb2dc4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1feb2dc4",
  null
  
)

export default component.exports