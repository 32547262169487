









import CharacterDisplay from './smart/CharacterDisplay.vue';

export default {
  props: {
    setCountTargetToFight:{
      type: Function,
      default: null
    },
  },
  components: {
    CharacterDisplay,
  },
};
